(function () {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionStartController', SessionStartController);

    /* @ngInject */
    function SessionStartController($state, $q, $filter, Feature, Session, $mdDialog, filter, conversation, isMobileBrowser, SessionPlayerService, Setting, Principal, User) {
        var vm = this;

        vm.loadingData = false;
        vm.usePersonalSession = window.location.href.includes('conversations') ? true : false;
        vm.personalSessionModel = null;
        vm.sessionSetting = [];

        vm.groupConversation = filter;
        vm.conversation = conversation;
        vm.isMobileBrowser = isMobileBrowser
        vm.createPersonalSession = Feature.isActive('feature_airsession_personal_session');

        vm.sessionModel = {
            id: null,
            topic: null,
            agenda: null,
            session_no: null,
            passcode: null,
            url: null,
            duration: null,
            timezone: null,
            start_time: new Date(),
            type: 'INSTANT',
            status: 'OPEN',
            owner: null,
            settings: {
                host_video: false,
                participant_video: false,
                mute_upon_entry: false
            },
            recurring_settings: null,
            session_users: [],
            created_by: null,
            created_on: null,
            last_modified_by: null,
            last_modified_on: null
        };

        vm.close = function () {
            sessionStorage.removeItem('context');
            $mdDialog.cancel();
        };

        vm.startSession = function () {
            if (vm.groupConversation != null && vm.conversation != null) {
                if(vm.groupConversation == "private_conversation") { 
                    for (var i = 0; i < vm.conversation.users.length; i++) {
                        if (vm.conversation.users[i].user_id != Principal.getUserId()) {
                            var obj = {
                                is_guest: false,
                                user_id: vm.conversation.users[i].user_id,
                                role: 'PARTICIPANT'
                               };
                               vm.sessionModel.session_users.push(obj);
                           }
                       };
                    if(vm.personalSessionModel.settings) {
                        vm.sessionModel.settings = vm.personalSessionModel.settings
                    }
                    Session.save(vm.sessionModel, function (result) {
                        vm.sessionModel = result;
                        startSessionPlayer();
                    }, onSaveError);
                }
                else {
                    $q.all([
                        Session.get({
                            id: vm.conversation.session_no,
                            is_session_no: true,
                            for_launch: true,
                            add_user: true
                        }).$promise
                    ])
                        .then(function (results) {
                            vm.sessionModel = results[0];
                            startSessionPlayer();
                        })
                }
            } else if (vm.usePersonalSession) {
                $q.all([
                    Session.get({
                        id: vm.personalSessionModel.session_no,
                        is_session_no: true,
                        for_launch: true,
                        is_web: true
                    }).$promise
                ])
                    .then(function (results) {
                        vm.sessionResult = results[0];
                        if (vm.sessionResult.topic != vm.sessionModel.topic) {
                            vm.sessionResult.topic = vm.sessionModel.topic;
                            Session.updateScheduled(vm.sessionResult, function (result) {
                                startSessionPlayer();
                            }, onSaveError);
                        } else {
                            startSessionPlayer();
                        }

                    })
            } else {
                Session.save(vm.sessionModel, function (result) {
                    vm.sessionModel = result;
                    startSessionPlayer();
                }, onSaveError);
            };
        };

        vm.updateTopic = function () {
            setTopic();
        };

        function startSessionPlayer() {
            $mdDialog.hide();
            var sessionNo = null;
            var sessionSettings = null;

            if (vm.groupConversation != null && vm.conversation != null) {
                sessionNo = vm.sessionModel.session_no;
                sessionSettings = vm.sessionModel.settings
            } else if (vm.usePersonalSession) {
                sessionNo = vm.personalSessionModel.session_no;
                sessionSettings = {
                    host_video: vm.sessionModel.settings.host_video
                };
            } else {
                sessionNo = vm.sessionModel.session_no;
                sessionSettings = null;
            };

            SessionPlayerService.setSessionSettings(sessionSettings);

            $state.go('storm.sessions.player', {
                'session_no': sessionNo
            });
        };

        function onSaveError() {
            vm.isSaving = false;
        };

        function loadData() {
            vm.loadingData = true;

            $q.all([
                Setting.getSystemTimezones({
                    size: 100
                }).$promise,
                Setting.getSessionSettings().$promise,
                User.getPersonalSession().$promise
            ])
                .then(function (results) {
                    vm.timezones = results[0];
                    setSettingSessionModel(results[1]);
                    vm.personalSessionModel = results[2];
                    setTopic();
                    vm.loadingData = false;

                    if(vm.groupConversation) {
                        vm.startSession();
                    }
                });
        };

        function setSettingSessionModel(settings) {
            angular.forEach(settings, function (setting) {
                if (setting.category == 'session_setting') {
                    vm.sessionSetting = setting;

                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.name == 'host_video' && settingField.value == 'true') {
                            vm.sessionModel.settings.host_video = true;
                        } else if (settingField.name == 'participant_video' && settingField.value == 'true') {
                            vm.sessionModel.settings.participant_video = true;
                        } else if (settingField.name == 'join_before_host' && settingField.value == 'true') {
                            vm.sessionModel.settings.join_before_host = true;
                        } else if (settingField.name == 'mute_upon_entry' && settingField.value == 'true') {
                            vm.sessionModel.settings.mute_upon_entry = true;
                        } else if (settingField.name == 'sign_in_required' && settingField.value == 'true') {
                            vm.sessionModel.settings.sign_in_required = true;
                        } else if (settingField.name == 'enable_waiting_room' && settingField.value == 'true') {
                            vm.sessionModel.settings.enable_waiting_room = true;
                        } else if (settingField.name == 'record_session' && settingField.value == 'true') {
                            vm.sessionModel.settings.record_session = true;
                        } else if (settingField.name == 'recurring_Session' && settingField.value == 'true') {
                            vm.sessionModel.settings.recurring_Session = true;
                        } else if (settingField.name == 'timezone_id') {
                            vm.sessionModel.timezone = settingField.value;
                        };
                    });
                }
            });
        };

        function setTopic() {
            if(vm.personalSessionModel.settings) {
                vm.sessionModel.settings.host_video = vm.personalSessionModel.settings.host_video;
            }
            if(vm.groupConversation) {
                if(vm.groupConversation === "private_conversation") {
                    vm.sessionModel.topic = Principal.getUserInfo().first_name + $filter('translate')('atheer.session.startSession.airsession');
                }
            }
            else {
                if (vm.usePersonalSession) {
                    vm.sessionModel.topic = Principal.getUserInfo().first_name + $filter('translate')('atheer.session.startSession.personal');
                } else {
                    vm.sessionModel.topic = Principal.getUserInfo().first_name + $filter('translate')('atheer.session.startSession.airsession');
                }
            }
        };

        loadData();
    }
})();
