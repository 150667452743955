(function () {
	'use strict';

	angular.module('atheer.airform').controller('AirFormController', AirFormController);

	/* @ngInject */
	function AirFormController(
		$state,
		$scope,
		$sce,
		BuildLanguages,
		$location,
		$window,
		$rootScope
	) {
		var vm = this;
		vm.ngxUrlTrusted = null;
		vm.isIFrameDataLoaded = false;

		function setNgxPageUrl() {
			var ngxUrl = null;
			var buildLanguage = BuildLanguages.getBuildLanguage();
			var path = $location.path();
			var hostUrl =
				location.hostname === 'localhost'
					? 'https://' + location.hostname + ':4200'
					: location.origin;

			if ($state.current.name == 'storm.admin-airforms') {
				ngxUrl = hostUrl + '/ngx/airform';
			} else if ($state.current.name == 'storm.admin-airform-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/airform/' + id;
			} else if ($state.current.name == 'storm.admin-airform-dashboard') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/airform/insights/' + id;
			} else if ($state.current.name == 'storm.admin-job-templates') {
				ngxUrl = hostUrl + '/ngx/config/template';
			} else if ($state.current.name == 'storm.admin-job-template-detail') {
				var id = path.split('/')[path.split('/').length - 1];
				ngxUrl = hostUrl + '/ngx/config/activityTemplate/'+id+'/definition';
			} else if($state.current.name == 'storm.admin-job-template-detail-definition') {
				var id = path.split('/')[path.split('/').length - 2];
                ngxUrl = hostUrl + '/ngx/config/activityTemplate/'+id+'/definition';
			} else if ($state.current.name == 'storm.admin-job-template-detail-card') {
				var id = path.split('/')[path.split('/').length - 2];
				ngxUrl = hostUrl + '/ngx/config/activityTemplate/'+id+'/card';
			} else if ($state.current.name == 'storm.admin-job-template-detail-board') {
				var id = path.split('/')[path.split('/').length - 2];
				ngxUrl = hostUrl + '/ngx/config/activityTemplate/'+id+'/board';
			} else if ($state.current.name == 'storm.admin-data-exports') {
				ngxUrl = hostUrl + '/ngx/config/template/dataexport';
			} else if ($state.current.name == 'storm.admin-data-export-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/config/template/dataexport/' + id;
			} else if ($state.current.name == 'storm.admin-step-templates') {
				ngxUrl = hostUrl + '/ngx/config/template/step';
			} else if ($state.current.name == 'storm.admin-step-template-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/config/template/step/' + id;
			} else if ($state.current.name == 'storm.admin-datasets') {
				ngxUrl = hostUrl + '/ngx/config/template/dataset';
			} else if ($state.current.name == 'storm.admin-dataset-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/config/template/dataset/' + id;
			} else if ($state.current.name == 'storm.admin-categories') {
				ngxUrl = hostUrl + '/ngx/config/template/categories';
			} else if ($state.current.name == 'storm.admin-notification-templates') {
				ngxUrl = hostUrl + '/ngx/config/template/notification';
			} else if ($state.current.name == 'storm.admin-scheduler-list') {
				ngxUrl = hostUrl + '/ngx/config/template/job-scheduler-list';
			} else if ($state.current.name == 'storm.admin-airform-review-flows') {
				ngxUrl = hostUrl + '/ngx/config/template/reviewflows';
			} else if ($state.current.name == 'storm.admin-airform-review-flow') {
				ngxUrl = hostUrl + '/ngx/config/template/reviewflow';
			} else if ($state.current.name == 'storm.admin-airform-review-flow-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/config/template/reviewflow/' + id;
			} else if ($state.current.name == 'storm.admin-scheduler-detail') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/config/template/job-scheduler/' + id + '/details';
			} else if ($state.current.name == 'storm.admin-airforms-preview') {
				var id = path.split('/').pop();
				ngxUrl = hostUrl + '/ngx/airforms-preview/' + id;
			} else {
				ngxUrl = ngxUrl = hostUrl + '/ngx/airform';
			}

			vm.ngxUrlTrusted = $sce.trustAsResourceUrl(ngxUrl);
			$rootScope.selectedLeftNavMenu = 'airforms';
		}

		function handleNgxEvent(event) {
			var hostUrl =
				location.hostname === 'localhost'
					? 'https://' + location.hostname + ':4200'
					: location.origin;
			if (event.origin !== hostUrl) return;
			if (event.data.ngxLocationChanged) {
				var ngState = event.data.ngxLocationChanged.ngState;
				var id = event.data.ngxLocationChanged.id;
				vm.isIFrameDataLoaded = true;
				scopeApply();

				if (id == null) {
					$state.transitionTo(ngState, null, {
						location: true,
						inherit: true,
						relative: $state.$current,
						notify: false
					});
				} else {
					$state.transitionTo(
						ngState,
						{
							id: id
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false
						}
					);
				}
			}
		}

		var scopeApply = function (fn) {
			var phase = $scope.$root.$$phase;
			if (phase !== "$apply" && phase !== "$digest") {
				return $scope.$apply(fn);
			}
		};

		$window.addEventListener('message', handleNgxEvent, false);

		// Unregister
		$scope.$on('$destroy', function () {
			window.removeEventListener('message', handleNgxEvent, false);
		});

		setNgxPageUrl();
	}
})();
