(function () {
    'use strict';

    angular
        .module('atheer.conversation')
        .directive('conversationMessageList', conversationMessageList);

    function conversationMessageList() {
        var directive = {
            scope: {
                conversation: '=',
                conversationId: '=',
                emptyIllustration: '=',
                isReadOnly: '=',
                sessionModel: '='
            },
            bindToController: true,
            controller: conversationMessageListController,
            controllerAs: 'vm',
            templateUrl: 'modules/conversation/conversation-message-list.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function conversationMessageListController($rootScope, $scope, $sce, $mdDialog, $mdToast, $state, $timeout, $q, $filter, $anchorScroll, Content, Message, $mdPanel, Principal, Conversation, ConversationModelService, AlertService, triSettings, Feature, $mdSidenav, Experience) {
        var vm = this;

        vm.togglePanel = function () {
            $mdSidenav('conversationPanel').toggle()
                .then(function () {

                });
        };

        vm.illustartionEmptyScreenId = "conversation";
        if (vm.conversationId) {
            vm.illustartionEmptyScreenId = "message";
        }


        if (vm.emptyIllustration == "session-conv") {
            vm.illustartionEmptyScreenId = "session_chat";
        }

        var converter = new showdown.Converter();
        converter.setOption('emoji', true);

        var conversationMessageInternalListener = null;
        var refreshConversationListener = null;
        var pubNubConversationListener = null;

        vm.loadingData = false;
        vm.reachedTop = false;
        vm.userId = Principal.getUserId();
        vm.currentDate = null;
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;
        vm.contentLoading = false;
        vm.isTyping = false;
        vm.messages = [];
        vm.contentMessageIds = [];
        vm.contentMessages = [];
        vm.messageGroupingThreshold = 24 * 60 * 60 * 1000 // 1 day threshold

        vm.currentMessage = null;

        vm.page = 0;
        vm.size = 20;

        vm.dateFormats = {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'MMMM DD, YYYY'
        };

        vm.status = {
            loading: false,
            loaded: false
        };

        vm.isLoader = false;

        vm.loadMoreMessages = function () {
            if (!vm.status.loading && !vm.reachedTop) {
                vm.status.loading = true;
                vm.currentMessage = 'message-' + vm.messages[vm.messages.length - 1].id
                $q.all([
                    Message.get({
                        query: "conversationId==" + vm.conversationId,
                        sort: "created_on,desc",
                        page: vm.page,
                        size: vm.size,
                    }).$promise
                ])
                    .then(function (results) {
                        angular.forEach(results[0], function (message) {
                            vm.messages.push(transformMessage(message));
                        });

                        groupMessages();

                        if (results[0].length != vm.size) {
                            vm.reachedTop = true;
                            vm.messages[vm.messages.length - 1].is_top = true
                        }

                        vm.page = vm.page + 1;
                        $timeout(function () {
                            vm.status.loading = false;
                        }, 500);

                        $timeout(function () {
                            $anchorScroll(vm.currentMessage);
                        }, 0);

                        vm.status.loaded = (vm.messages.length > 0);
                    });
            }
        };

        conversationMessageInternalListener = $rootScope.$on('conversationMessageInternal', function (event, messageInfo) {
            if (messageInfo.type == 'MESSAGE_CREATED') {
                addMessage(transformMessage(messageInfo.message));
            } else if (messageInfo.type == 'MESSAGE_CREATED_Id') {
                updateMessageId(messageInfo.message);
            } else if (messageInfo.type == 'MESSAGE_UPDATED') {
                updateMessage(transformMessage(messageInfo.message));
                groupMessages();
            } else if (messageInfo.type == 'MESSAGE_DELETED') {
                if (messageInfo.message.id == vm.messages[0].id) {
                    messageInfo.message.body = '';
                    $rootScope.$broadcast('conversationMessageInternal', {
                        type: 'UPDATE_LAST_MESSAGE',
                        message: vm.messages.length > 1 ? vm.messages[vm.messages.length - 1] : messageInfo.message
                    });
                }
                _.remove(vm.messages, function (item) {
                    return item.id === messageInfo.message.id;
                });
            } else if (messageInfo.type == 'CONTENT_CREATED' || messageInfo.type == 'AUDIO_CREATED') {
                vm.isLoader = true;
            };
        });

        refreshConversationListener = $rootScope.$on('refreshConversation', function (event, conversation) {
            vm.conversation = conversation;
            vm.conversationId = vm.conversation != null ? vm.conversation.id : null;
            loadMessages();
        });

        pubNubConversationListener = $scope.$on('conversationMessagePubNub', function (event, message) {
            $scope.$apply(function () {
                if (message.message.subject == 'CONVERSATION') {
                    handleMessage(message);
                };
            });
        });

        // Unregister
        $scope.$on('$destroy', function () {
            conversationMessageInternalListener = null;
            refreshConversationListener = null;
            pubNubConversationListener = null;
        });

        function addMessage(message, fromRealTime) {
            if (message.type == 'CONTENT') {
                vm.contentLoading = true;
            }

            $timeout(function () {
                vm.scrollToBottom = false;
            }, 0);

            if (message.type == 'TEXT' && !fromRealTime) {
                vm.isLoader = true;
            } else {
                vm.messages.unshift(formatMessageBody(message));
                vm.isLoader = false;
            }

            groupMessages();

            $rootScope.$broadcast('conversationMessageInternal', {
                type: 'UPDATE_LAST_MESSAGE',
                message: message
            });
            $timeout(function () {
                vm.scrollToBottom = true;
            }, 0);
        };

        function updateMessage(updatedMessage) {
            var messageIndex = vm.messages.findIndex(function (message) {
                return message.id === updatedMessage.id;
            });
            if (messageIndex >= 0) {
                vm.messages[messageIndex] = updatedMessage;
            };
        };

        function updateMessageId(updatedMessage) {
            vm.messages.unshift(formatMessageBody(updatedMessage));

            groupMessages();

            $rootScope.$broadcast('conversationMessageInternal', {
                type: 'UPDATE_LAST_MESSAGE',
                message: updatedMessage
            });

            $timeout(function () {
                vm.scrollToBottom = true;
            }, 0);

            vm.isLoader = false;
        };

        function handleMessage(pubNubMessage) {
            if (pubNubMessage.message.type == 'MESSAGE_CREATED') {
                if (pubNubMessage.message.data.conversation_id == vm.conversationId) {
                    if ($state.current.url != '/player/:session_no') {
                        Experience.getReadMessages({
                            userId: vm.userId,
                            conversationId: vm.conversationId
                        }, function (readMessage) {
                        });
                    }
                    var messageIndex = vm.messages.findIndex(function (message) {
                        return message.id === pubNubMessage.message.data.id;
                    });
                    if (messageIndex <= 0) {
                        addMessage(transformMessage(pubNubMessage.message.data), true);
                    }
                } else {
                    $rootScope.$broadcast('conversationMessageInternal', {
                        type: 'UPDATE_LAST_MESSAGE',
                        message: pubNubMessage.message.data
                    });
                }
                if ($state.current.url == '/player/:session_no') {
                    $rootScope.$broadcast('unReadMessages', pubNubMessage);
                }
            } else if (pubNubMessage.message.type == 'MESSAGE_UPDATED') {
                var messageIndex = vm.messages.findIndex(function (message) {
                    return message.id === pubNubMessage.message.data.id;
                });

                if (messageIndex >= 0) {
                    updateMessage(transformMessage(pubNubMessage.message.data));
                }
            } else if (pubNubMessage.message.type == 'MESSAGE_DELETED') {
                var messageIndex = vm.messages.findIndex(function (message) {
                    return message.id === pubNubMessage.message.data.id;
                });

                if (messageIndex == 0) {
                    var singleMessage = vm.messages[0];
                    if (vm.messages.length <= 1) {
                        singleMessage.body = '';
                    }
                    vm.messages.shift();
                    $rootScope.$broadcast('conversationMessageInternal', {
                        type: 'UPDATE_LAST_MESSAGE',
                        message: vm.messages.length >= 1 ? vm.messages[0] : singleMessage
                    });

                } else if (messageIndex > 0) {
                    vm.messages.splice(messageIndex, 1);
                }
            };
        };

        function formatMessageBody(message) {
            message.formattedBody = converter.makeHtml(message.body.replaceAll("\n", "<br>")).replaceAll(/&lt;br\s*\/?\&gt;/g, "<br>")
            return message;
        };

        function groupMessages() {
            for (var i = 0; i < vm.messages.length - 1; i++) {
                var currentMessage = vm.messages[i];
                var nextMessage = vm.messages[i + 1];

                nextMessage.group_end = false;

                // If next messages is from different users, mark this as the end of the group
                if (currentMessage.publisher_id != nextMessage.publisher_id) {
                    nextMessage.group_end = true;
                }

                // If next message and current message are send more than X seconds apart, mark this as the end
                var currentDate = new Date(currentMessage.created_on);
                var nextDate = new Date(nextMessage.created_on);
                if (Math.abs(currentDate.getTime() - nextDate.getTime()) > vm.messageGroupingThreshold) {
                    nextMessage.group_end = true;
                }

                // If next message and current message are from differet days, mark this as the end
                // Also put the time separator on current message
                if (currentDate.getFullYear() != nextDate.getFullYear() ||
                    currentDate.getMonth() != nextDate.getMonth() ||
                    currentDate.getDay() != nextDate.getDay()) {
                    nextMessage.group_end = true;
                    currentMessage.time_separate = true;
                }
            }

            // Last message is always end of a group
            if (vm.messages.length > 0) {
                vm.messages[0].group_end = true;
            }
        }

        /*
            Function to convert reaction unifid to html entity
            Ex. 1f486-200d-2642-fe0f -> &#x1F486;&#x200D;&#x2642;&#xFE0F;
        */
        function convertReactionToHtmlEntities(input) {
            var parts = input.split("-");
            var htmlEntities = parts.map(function (part) {
                return "&#x" + part.toUpperCase() + ";";
            });
            return htmlEntities.join("");
        }

        function transformMessage(message) {
            if (vm.conversation.type == 'PRIVATE_USER') {
                if (message.publisher_id == vm.userId) {
                    message.user_info = {
                        id: Principal.getUserInfo().id,
                        first_name: Principal.getUserInfo().first_name,
                        last_name: Principal.getUserInfo().last_name,
                        picture_id: Principal.getUserInfo().picture_id
                    };
                }
            } else {
                var userInfo = $filter('filter')(vm.conversation.users_info, {
                    id: message.publisher_id
                })[0];
                message.user_info = {
                    id: userInfo.id,
                    first_name: userInfo.first_name,
                    last_name: userInfo.last_name,
                    picture_id: userInfo.picture_id
                };
            };

            message.reaction_summary = {
                pinned: false,
                reaction_info: []
            };

            if (message.reactions && message.reactions.length > 0) {
                var messageIndex = message.is_pinned;

                if (messageIndex) {
                    message.reaction_summary.pinned = true;
                };

                angular.forEach(message.reactions, function (reaction) {
                    if (reaction.type != 'PINNED') {
                        var reactByMe = false;
                        var reactionSummary = message.reaction_summary.reaction_info.find(function (reactionInfo) {
                            return reactionInfo.type == reaction.type;
                        });

                        if (reaction.user_id === vm.userId) {
                            reactByMe = true;
                        }

                        if (reactionSummary != null) {
                            reactionSummary.count += 1;
                            if (reactByMe) {
                                reactionSummary.reactByMe = true;
                            }
                        } else {
                            var reactionSummary = {
                                type: reaction.type,
                                unified: reaction.unified,
                                webInfo: $sce.trustAsHtml(
                                    convertReactionToHtmlEntities(reaction.unified)
                                ),
                                count: 1,
                                reactByMe: reactByMe
                            }
                            message.reaction_summary.reaction_info.push(reactionSummary);
                        }
                    }
                })
            };

            function getReactionSummary(reactionType) {
                var reactionSummary = null;
                reactionSummary = message.reaction_summary.reaction_info.find(function (reactionInfo) {
                    return reactionInfo.type == reactionType;
                });
                return reactionSummary;
            };

            message = formatMessageBody(message);
            return message;
        };

        function addMessageStyle() {
            var style = "<style>" + ".mine .message.last:before{background:" + vm.primaryColor + "}</style>";
            angular.element("head").append(style);
        }

        function loadMessages() {
            vm.messages = [];
            vm.reachedTop = false;

            vm.page = 0;
            if (vm.conversationId && vm.conversationId != null) {
                vm.loadingData = true;
                $q.all([
                    Conversation.get({
                        id: vm.conversationId
                    }).$promise,
                    Message.get({
                        query: "conversationId==" + vm.conversationId,
                        sort: "created_on,desc",
                        page: vm.page,
                        size: vm.size,
                    }).$promise
                ])
                    .then(function (results) {
                        vm.conversation = results[0];

                        angular.forEach(results[1], function (message) {
                            vm.messages.push(transformMessage(message));
                        });

                        groupMessages();

                        if (vm.messages.length > 0) {
                            vm.currentMessage = "message-" + vm.messages[vm.messages.length - 1].id;
                        }

                        if (vm.conversation.type != 'PRIVATE_USER') {
                            vm.illustartionMessageName = vm.conversation.name;
                        } else {
                            vm.illustartionMessageName = vm.conversation.user_info.first_name;
                        }

                        vm.page = vm.page + 1;
                        vm.loadingData = false;
                        vm.scrollToBottom = true;
                    });
            } else {
                vm.messages = [];
                vm.loadingData = false;
            };
        };

        $rootScope.$on('conversationUserTypingList', function (event, usersTyping) {
            $scope.$apply(function () {
                vm.usersTyping = usersTyping;
                if (vm.usersTyping.length > 0 && vm.conversation.type == 'PRIVATE_USER') {
                    vm.isTyping = true;
                } else {
                    vm.isTyping = false;
                }
            });
        });

        if (!vm.sessionModel) {
            loadMessages();
        }
        addMessageStyle();

        $scope.$watch('vm.sessionModel', function (newValue) {
            if (angular.isDefined(vm.sessionModel) && vm.sessionModel != null) {
                loadMessages();
            }
        });
    };
})();
