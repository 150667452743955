(function() {
    "use strict";

    angular
        .module("atheer.user")
        .controller("GuestJoinController", GuestJoinController);

    /* @ngInject */
    function GuestJoinController(triSettings, deviceDetector, Guest, $state, Session, Auth, Principal, $cookies, $stateParams, $filter, PubNubService, $location, $window, $rootScope, $scope, ToastService, Experience) {
        var vm = this;
        var showLoaderListener = null;

        vm.triSettings = triSettings;
        vm.useDarkBackGroundLogos = true;
        vm.isError = false;
        vm.isLoading = false;
        vm.sessionCode1 = "";
        vm.sessionCode2 = "";
        vm.sessionCode3 = "";
        vm.sessionNumber1 = "";
        vm.sessionNumber2 = "";
        vm.sessionNumber3 = "";
        vm.sessionNumber4 = "";
        vm.sessionNumber5 = "";
        vm.guestUserId = null;
        vm.workspace = 'guest';
        vm.firstName = "";
        vm.lastName = "";
        vm.bearerToken = null;
        vm.sessionModel = null;
        vm.isWaitingForHost = false;
        vm.isInputChanged = false;
        vm.deviceId = null;
        vm.convChannelName = null;
        vm.buildInfoSettings = $rootScope.buildInfo;
        vm.sessionNo = null;
        vm.workspaceCode = null;
        vm.isMobileBrowser = deviceDetector.isMobile();
        vm.launchSession = false;
        vm.termsAndPrivacy = false;
        vm.showTermsAndPrivacy = true;
        vm.poweredByLink = "http://www.atheerair.com/";
        vm.primaryColor;

        vm.termsLink = "http://www.atheerair.com/";
        vm.privacyLink = "https://www.atheerair.com/privacy-policy/";

        function loadAll() {
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;
            vm.primaryColor = vm.triSettings.getSkinModel().primary_color_hex;
            $cookies.put('isGuest', true);

            setThemeColor(vm.sessionNo ? 1000 : 200);

            if ($stateParams.session_no && $stateParams.workspace_code && $stateParams.launch_session) {
                vm.sessionNo = $stateParams.session_no;
                vm.workspaceCode = $stateParams.workspace_code;
                vm.launchSession = $stateParams.launch_session;
                vm.isLoading = true;
            }

            if (vm.sessionNo && vm.workspaceCode) {
                setSessionNoAndCode();
            }

            if($rootScope.companySetting) {
                vm.termsLink = $rootScope.companySetting.terms_url;
                vm.privacyLink = $rootScope.companySetting.privacy_policy_url;
            };

            var host = $location.host();
            if (angular.isDefined(host) && host.startsWith("rigpartner.")) {
                vm.termsLink = "https://rigquip.com/terms-conditions/";
                vm.privacyLink = "https://rigquip.com/privacy-policy/";
            };
            createGuestUser();
        };

        function setSessionNoAndCode() {
            vm.sessionNumber1 = vm.sessionNo.charAt(0);
            vm.sessionNumber2 = vm.sessionNo.charAt(1);
            vm.sessionNumber3 = vm.sessionNo.charAt(2);
            vm.sessionNumber4 = vm.sessionNo.charAt(3);
            vm.sessionNumber5 = vm.sessionNo.charAt(4);

            vm.sessionCode1 = vm.workspaceCode.charAt(0);
            vm.sessionCode2 = vm.workspaceCode.charAt(1);
            vm.sessionCode3 = vm.workspaceCode.charAt(2);
        }

        function createGuestUser() {
            vm.isLoading = true;
            var guestUser = {};
            var host = $location.host();

            guestUser.unique_id = $cookies.get('deviceId');
            if (!guestUser.unique_id) {
                var domainParts = host.split('.');
                var parentDomain = domainParts[1] + '.' + domainParts[2];

                guestUser.unique_id = Date.now().toString(36) + Math.random().toString(36).substr(5);
                window.localStorage.setItem('deviceId', guestUser.unique_id);
                $cookies.put('deviceId', guestUser.unique_id, {
                    domain: parentDomain
                });

                guestUser.first_name = (deviceDetector.browser === 'ms-edge-beta' ? deviceDetector.browser.split('-beta')[0] : deviceDetector.browser);
                guestUser.last_name = deviceDetector.os;
            }

            Guest.createGuestUser(guestUser, vm.workspace)
                .then(function(result) {
                    Guest.setBearerToken(result.data.id_token);
                    Guest.setGuestUserId(result.data.user_id);

                    vm.guestUserId = result.data.user_id;
                    vm.bearerToken = result.data.id_token;
                    vm.deviceId = result.data.device_id;
                    vm.userNo = result.data.user_no;

                    if (result.data.hasOwnProperty("first_name") && result.data.first_name != null) {
                        vm.firstName = result.data.first_name;
                    } else {
                        vm.firstName = (deviceDetector.browser === 'ms-edge-beta' ? deviceDetector.browser.split('-beta')[0] : deviceDetector.browser);
                    }
                    if (result.data.hasOwnProperty("last_name") && result.data.last_name != null) {
                        vm.lastName = result.data.last_name;
                    } else {
                        vm.lastName = deviceDetector.os;
                    }

                    PubNubService.init(result.data.user_id, result.data.messaging_config, result.data.channels, ['access-session']);
                    initPubNubHandler(result.data.channels.personal[0].name);

                    if (vm.sessionNo && vm.workspaceCode) {
                        if (vm.launchSession == 'true') {
                            vm.joinGuestSession(true);
                        } else {
                            vm.joinGuestSession(false);
                            vm.isLoading = false;
                        }
                    } else {
                        vm.isLoading = false;
                    }
                })
                .catch(function(err) {
                    vm.isLoading = false;
                    $state.go('account.signin');
                    ToastService.displayToast($filter('triTranslate')('atheer.guestJoin.messages.unableToCreateGuestUser'), 3000);
                });
        };

        function setThemeColor(duration) {
            setTimeout(function() {
                var spinners = document.getElementsByClassName("spinner");
                angular.forEach(spinners, function(spinner) {
                    spinner.style.backgroundColor = vm.triSettings.getSkinModel().primary_color_hex;
                });
            }, duration);
        };

        function resetVariables() {
            vm.isLoading = false;
        };

        function initPubNubHandler(channelName) {
            console.log('initPubNubHandler: ' + vm.convChannelName);
            vm.convChannelName = channelName;
            PubNubService.subscribe([vm.convChannelName], true);
        };

        function showLoader(event, isLoader) {
            vm.isLoading = isLoader;
            setThemeColor(200);
        };

        function redirectToJoin() {
            var host = $location.host();
            if (!host.startsWith('app')) {
                var hostUrl = $location.absUrl();
                var domain = $window.location.host.split('.')[0];
                var path = $location.path()

                var url = hostUrl.replace(domain, 'app').replace(path, '/sessions/player/' + vm.sessionNo + '/guest/' + vm.workspaceCode + '/firstName/' + vm.firstName + '/lastName/' + vm.lastName + '/sessionInvalid/' + true);
                $window.location.href = url;
            } else {
                var hostUrl = $location.absUrl();
                var domain = $window.location.host.split('.')[0];
                var path = $location.path()

                var url = hostUrl.replace(domain, domain).replace(path, '/sessions/player/' + vm.sessionNo + '/guest/' + vm.workspaceCode + '/firstName/' + vm.firstName + '/lastName/' + vm.lastName + '/sessionInvalid/' + true);
                $window.location.href = url;
            }
        };

        vm.joinGuestSession = function(launchSession) {
            var workspaceCode = vm.sessionCode1 + vm.sessionCode2 + vm.sessionCode3;
            var sessionNumber = vm.sessionNumber1 + vm.sessionNumber2 + vm.sessionNumber3 + vm.sessionNumber4 + vm.sessionNumber5;

            vm.isLoading = true;

            setThemeColor(200);

            Guest.validateWorkspaceCode(workspaceCode, vm.workspace).then(function(result) {
                vm.workspace = result.data.api_url.split('.')[0].split(':')[1].split('/')[2];
                var isGuest = $cookies.get('isGuest');
                if (!isGuest) {
                    $cookies.put('isGuest', true);
                }

                var host = $location.host();

                if (host.startsWith('app')) {
                    var hostUrl = $location.absUrl();
                    var path = $location.path()
                    var url = hostUrl.replace('app', vm.workspace).replace(path, '/join/' + sessionNumber + '/' + workspaceCode + '/true');
                    $window.location.href = url;
                } else {
                    Auth.loginWithToken(vm.bearerToken).then(function(response) {
                        var data = {
                            first_name: vm.firstName,
                            last_name: vm.lastName
                        };
                        Guest.updateGuestUser(vm.guestUserId, vm.workspace, data).then(function(res) {
                            var host = $location.host();
                            var domainParts = host.split('.');
                            var parentDomain = domainParts[1] + '.' + domainParts[2];
                            Principal.identity().then(function(user) {
                                Guest.getSessionDetails(vm.guestUserId, sessionNumber, vm.workspace).then(function(result) {
                                    Session.get({
                                        id: sessionNumber,
                                        for_launch: true,
                                        is_session_no: true
                                    }, onSuccess, onError);

                                    function onSuccess(result) {
                                        if (!result.id && result.result && result.result === "NOT_FOUND") {
                                            resetVariables();
                                            ToastService.displayToast($filter('triTranslate')('atheer.guestJoin.messages.airSessionExpired'), 3000);
                                            Auth.logout();
                                            redirectToJoin();
                                        } else {
                                            vm.sessionModel = result;
                                            if (launchSession) {
                                                Experience.createSessionActivity({
                                                    object: vm.sessionModel,
                                                    user_id: user.id,
                                                    activity_type: 'TERMS_ACCEPTED',
                                                    properties: null
                                                }, function(result) {
                                                    $state.go('storm.sessions.player', {
                                                        'session_no': vm.sessionModel.session_no
                                                    });
                                                });
                                            }
                                            vm.isError = false;
                                            PubNubService.unsubscribe([vm.convChannelName]);
                                        }
                                    };

                                    function onError(error) {
                                        resetVariables();
                                        ToastService.displayToast($filter('triTranslate')('atheer.guestJoin.messages.airSessionExpired'), 3000);
                                        Auth.logout();
                                        redirectToJoin();
                                    };
                                }).catch(function(err) {
                                    Auth.logout();
                                    //redirectToJoin();
                                    vm.isError = true;
                                    resetVariables();
                                });
                            });
                        }).catch(function(err) {
                            ToastService.displayToast($filter('triTranslate')('atheer.guestJoin.messages.unableToUpdateGuestUser'), 3000);
                            Auth.logout();
                            resetVariables();
                        });
                    });
                }
            }).catch(function(error) {
                redirectToJoin();
                vm.isError = true;
                resetVariables();
            });
        };

        vm.checkPasscode = function (modelVal, code) {
            if (modelVal != '' && modelVal != null && code < 8) {
                var input_elements = document.getElementsByClassName('inline_input');
                input_elements[code].focus();
            };
        };

        vm.onNumberInputKeydown = function (e) {
            if (
                ![
                    "1", "2", "3", "4", "5", "6", "7","8", "9", "0",
                    "Backspace",
                    "Tab",
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                ].includes(e.key)
            ) {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        vm.redirectToSignin = function() {
            $state.go('account.signin');
        };

        showLoaderListener = $rootScope.$on('show-loader', showLoader);

        $rootScope.$on('$destroy', function() {
            showLoaderListener = null;
        });

        loadAll();
    }
})();
