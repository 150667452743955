(function () {
    'use strict';

    angular
        .module('atheer.sdk')
        .controller('SdkDocumentationController', SdkDocumentationController);

    /* @ngInject */
    function SdkDocumentationController($rootScope, $location, Principal, $scope, $sce, $cookies, $q, Setting, $mdToast, $filter, $mdDialog, Feature, AuthServerProvider) {
        var vm = this;
        var host = $location.host();
        var domain = Principal.getWorkspaceDomain();
        var authToken = AuthServerProvider.getToken();
        var apiUrl = "https://openapi.atheer.dev?workspace=" + domain + "&instance=dev&authToken=" + authToken;

        vm.viewType = "doc";
        vm.webHooksEnabled = Feature.isActive('feature_airsdk_webhooks');

        if (angular.isDefined(host) && host.endsWith(".dev")) {
            apiUrl = "https://openapi.atheer.dev?workspace=" + domain + "&instance=dev&authToken=" + authToken;
        } else if (angular.isDefined(host) && host.endsWith(".qa")) {
            apiUrl = "https://openapi.atheer.dev?workspace=" + domain + "&instance=qa&authToken=" + authToken;
        } else if (angular.isDefined(host) && host.endsWith(".si")) {
            apiUrl = "https://openapi.atheer.dev?workspace=" + domain + "&instance=si&authToken=" + authToken;
        } else if (angular.isDefined(host) && host.endsWith(".io")) {
            apiUrl = "https://openapi.atheer.io?workspace=" + domain + "&instance=io&authToken=" + authToken;
        } else {
            apiUrl = "https://openapi.atheer.dev?workspace=" + domain + "&instance=dev&authToken=" + authToken;
        };
        vm.hostUrl = $sce.trustAsResourceUrl(apiUrl);
    }
})();
