(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserPickerController', UserPickerController);

    /* @ngInject */
    function UserPickerController($scope, $state, triSettings, User, allowMultiSelect, filter, userslist, ParseLinks, AlertService, $mdDialog, Principal, Feature, isAdmin, isSession, isScheduledSession, isConversationSession, isSmartScan, paginationConstants) {
        var vm = this;

        vm.loadingData = false;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.removeFilter = removeFilter;
        vm.changeSort = changeSort;
        vm.allowMultiSelect = allowMultiSelect;
        vm.userFilter = filter == null ? [] : filter;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = "";
        vm.page = 1;
        vm.reverse = true;
        vm.userFilter = userslist.length == 0 ? vm.userFilter : userslist;
        vm.isAdmin = isAdmin;
        vm.isConversationSession = isConversationSession;
        vm.isSmartScan = isSmartScan;
        vm.isGuestFeature = Feature.isActive('feature_airsession_invite_guest_user');
        vm.illustartionEmptyScreenId = vm.isSession ? "no_users_session" : "no_users_search";
        $scope.secondaryColor = triSettings.getSkinModel().secondary_color_hex;

        vm.totalItems = null;
        vm.currentSelectedUsers = [];

        vm.isEmailGuestEnabled = Feature.isActive('feature_airsession_enable_email_invite');
        vm.isSmsGuestEnabled = Feature.isActive('feature_airsession_enable_sms_invite');
        vm.isGuestButtonVisible = true;
        if (isScheduledSession) {
            vm.isGuestButtonVisible = vm.isEmailGuestEnabled || vm.isSmsGuestEnabled;
        }

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            order: '-first_name',
            page: 1
        };

        vm.filter = {
            show: false,
            options: {
                debounce: 500
            }
        };

        function formQuery() {
            if (!vm.query.filter) {
                return;
            };

            var queryArr = vm.query.filter.split(" ");
            var searchQuery = "(";

            searchQuery += 'first_name=re="' + vm.query.filter + '"';
            searchQuery += ',last_name=re="' + vm.query.filter + '"';
            searchQuery += ',email=re="' + vm.query.filter + '"';

            searchQuery += ")";

            return searchQuery
        }

        function loadAll() {
            vm.loadingData = true;
            var query = null;
            if (vm.userFilter.length != 0) {
                query = 'id=out=(' + vm.userFilter.toString() + ')';
            } else {
                if (!isSmartScan) {
                    query = 'id=out=(' + Principal.getUserId() + ')';
                }
            }

            if (vm.query.filter.length != 0) {
                if (query == null) {
                    query = formQuery();
                } else {
                    query = formQuery() + ';' + query;
                }
            }
            User.query({
                query: query,
                page: 0,
                size: vm.query.limit,
                sort: sort(),
                check_admin: vm.isAdmin
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'first_name') {
                    result = ['first_name,' + (vm.reverse ? 'asc' : 'desc')];
                    vm.predicate = 'first_name';
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = 1;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function changeSort(newSort) {
            if (vm.predicate == newSort) {
                vm.reverse = !vm.reverse;
            } else {
                vm.reverse = true;
                vm.predicate = newSort;
            }

            transition();

        }

        function transition() {
            var query = null;
            if (vm.userFilter.length != 0) {
                query = 'id=out=(' + vm.userFilter.toString() + ')';
            } else {
                if (!isSmartScan) {
                    query = 'id=out=(' + Principal.getUserId() + ')';
                }
            }

            if (vm.query.filter.length != 0) {
                if (query == null) {
                    query = formQuery();
                } else {
                    query = formQuery() + ';' + query;
                }
            }

            vm.promise = User.query({
                query: query,
                page: vm.page - 1,
                size: vm.query.limit,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                check_admin: vm.isAdmin
            }).$promise;

            vm.promise.then(function (data, headers) {
                vm.queryCount = vm.totalItems;
                vm.users = data;
            });
        }

        function removeFilter() {
            vm.filter.show = false;
            vm.query.filter = '';
            loadAll();

            if (vm.filter.form && vm.filter.form.$dirty) {
                vm.filter.form.$setPristine();
            }
        }

        vm.updateParentObject = function () {
            $mdDialog.hide(vm.currentSelectedUsers);
        }

        vm.onDeselectUser = function (user) {
            var updatedSelectedUsers = vm.currentSelectedUsers.filter(function (value) {
                return value.id != user.id || value.guest_invite_value != user.guest_invite_value;
            });

            vm.currentSelectedUsers = updatedSelectedUsers;
        }

        function resetModal() {
            loadAll();
        };

        vm.close = function () {
            $mdDialog.cancel();
        }

        vm.loadAll();
    }
})();
